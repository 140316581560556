import React from "react";
import "./Slideshow.css";
// inspired by https://tinloof.com/blog/how-to-build-an-auto-play-slideshow-with-react
const slideshowDelay = 2500;
const slideshowFilesRootRelativePath = "/images/slideshow-images/";
const slideshowFileNames = [
  "1 Laura Berry Self Portrait.jpg",
  "2 Ashley Frankum Burn 1.jpg",
  "3 Map of Ashby Shellmound by six residents.jpg",
  "4 community created art map.jpg",
  "5 Dartagnan Lloyd Map of Ashby Shellmound Camp area.png",
  "7 Pat Thomas Untitled.jpg",
  "8 Grimm Untitled.JPG",
  "9 Ray Leichter percussive instrument.jpg",
  "10 Grimm Untitled.JPG",
  "11 Grimm Self Portrait Smoking.JPG",
  "12 Ashley Frankum Untitled Mannequin.jpg",
  "13 Alastair and Dartagnan interview at Ashby Shellmound.jpg",
  "14 Dartagnan Lloyd Poem about connection between artworks.jpg",
  "15 Dartagnan Lloyd Direction Finder.jpg",
  "16 Laura Berry Untitled Bed.jpg",
  "17 Pat Thomas and Grimm Untitled Merged Artwork.jpg",
  "18 Ray Leichter performing at Ashby Shellmound.png",
  "19 Pat Thomas painting at Ashby Shellmound.png",
  "21 held up image.jpeg",
  "20 new map.jpeg",
];
const Slideshow = () => {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === slideshowFileNames.length - 1 ? 0 : prevIndex + 1
        ),
      slideshowDelay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  const imagePath = `${process.env.PUBLIC_URL}${slideshowFilesRootRelativePath}${slideshowFileNames[index]}`;
  const s3ImagePath =
    `https://ashby-shellmound-media.s3.us-west-2.amazonaws.com/images/slideshow-images/${slideshowFileNames[index]}`.replace(
      " ",
      "+"
    );
  console.log("image path: ", imagePath);
  console.log("index: ", index);
  return (
    <div className="slideshow">
      <img
        className="slideshow-image"
        src={s3ImagePath}
        alt="cannot display"
        ß
      ></img>
      <div className="slideshowDots">
        {slideshowFileNames.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
