const images = [
    {  
      'address': 0,
      'filename': '1 Grimm right.JPG',
      'author': 'Grimm',
      'width': 905,
      'height': 1168,
      'pairings': [
        {
          'pair': 1,
          'position': 'right',
          'description': 'Two different places, but sharing the same shadow. The darkness that surrounds this kind of thing is the same.\n\n- Grimm',
          'composite': ''
        },
      ]
    },
    {
      'address': 1,
      'filename': '1 Laura left.JPG',
      'author': 'Laura',
      'width': 720,
      'height': 960,
      'pairings': [
        {
          'pair': 0,
          'position': 'left',
          'description': 'Two different places, but sharing the same shadow. The darkness that surrounds this kind of thing is the same.\n\n- Grimm',
          'composite': ''
        },
      ]
    },
    {
      'address': 2,
      'filename': '2 Grimm.jpg',
      'author': 'Grimm',
      'width': 750,
      'height': 562,
      'pairings': [
        {
          'pair': 3,
          'position': '',
          'description': 'Similar perspectives about two different things, one analog and one digital.\n\n- Grimm',
          'composite': ''
        },
      ]
    },
    {
      'address': 3,
      'filename': '2 Pat.jpg',
      'author': 'Pat',
      'width': 750,
      'height': 1098,
      'pairings': [
        {
          'pair': 2,
          'position': '',
          'description': 'Similar perspectives about two different things, one analog and one digital.\n\n- Grimm',
          'composite': ''
        },
      ]
    },
    {
      'address': 4,
      'filename': '3 Dart.jpg',
      'author': 'D\'art',
      'width': 1000,
      'height': 1333,
      'pairings': [
        {
          'pair': 5,
          'position': '',
          'description': 'I see parallels in color and composition, almost like a negative of the same picture but not.\n\n- Ashley',
          'composite': ''
        },
        {
          'pair': 35,
          'position': '',
          'description': 'Color my feelings.\n\n- Laura',
          'composite': ''
        },
      ]
    },
    {
      'address': 5,
      'filename': '3 Ashley.jpg',
      'author': 'Ashley',
      'width': 750,
      'height': 1002,
      'pairings': [
        {
          'pair': 4,
          'position': '',
          'description': 'I see parallels in color and composition, almost like a negative of the same picture but not.\n\n- Ashley',
          'composite': ''
        },
      ]
    },
    {
      'address': 6,
      'filename': '4 Ashley left.jpg',
      'author': 'Ashley',
      'width': 750,
      'height': 1099,
      'pairings': [
        {
          'pair': 7,
          'position': 'left',
          'description': 'I was burned with fire. This is like a negative of it and in a way it really is.\n\n- Ashley',
          'composite': ''
        },
      ]
    },
    {
      'address': 7,
      'filename': '4 Grimm right.jpg',
      'author': 'Grimm',
      'width': 750,
      'height': 1002,
      'pairings': [
        {
          'pair': 6,
          'position': 'right',
          'description': 'I was burned with fire. This is like a negative of it and in a way it really is.\n\n- Ashley',
          'composite': ''
        },
      ]
    },
    {
      'address': 8,
      'filename': '5 Ashley.jpg',
      'author': 'Ashley',
      'width': 750,
      'height': 493,
      'pairings': [
        {
          'pair': 9,
          'position': '',
          'description': 'I see faces in both of these, my swirly face and a skull face in the flames.\n\n- Ashley',
          'composite': ''
        },
      ]
    },
    {
      'address': 9,
      'filename': '5 Grimm.jpg',
      'author': 'Grimm',
      'width': 750,
      'height': 563,
      'pairings': [
        {
          'pair': 8,
          'position': '',
          'description': 'I see faces in both of these, my swirly face and a skull face in the flames.\n\n- Ashley',
          'composite': ''
        },
      ]
    },
    {
      'address': 10,
      'filename': '6 Ashley.jpg',
      'author': 'Ashley',
      'width': 1500,
      'height': 2000,
      'pairings': [
        {
          'pair': 11,
          'position': '',
          'description': 'I see the whole skyline of Berkeley from the ridge to the bay portrayed in mine and hers, from the sky to the ground and water.\n\nDifferent overlays of different kindred experiences like we\'ve always had each other in each other\'s mind even though far apart.\n\n- D’artagnan',
          'composite': ''
        },
      ]
    },
    {
      'address': 11,
      'filename': '6 Dart.jpg',
      'author': 'D\'art',
      'width': 750,
      'height': 982,
      'pairings': [
        {
          'pair': 10,
          'position': '',
          'description': 'I see the whole skyline of Berkeley from the ridge to the bay portrayed in mine and hers, from the sky to the ground and water.\n\nDifferent overlays of different kindred experiences like we\'ve always had each other in each other\'s mind even though far apart.\n\n- D’artagnan',
          'composite': ''
        },
      ]
    },
    {
      'address': 12,
      'filename': '7 Dart.jpg',
      'author': 'D\'art',
      'width': 750,
      'height': 570,
      'pairings': [
        {
          'pair': 13,
          'position': '',
          'description': 'Leavings and arrivings\n\nSunsets and sunrises\n\nRoads diverge and come back together\n\nSometimes we take an exit on the highway of life\n\nAnd sometimes we rejoin the stream of traffic\n\nAll reflected in the sky\n\nBefore the final vistas show themselves\n\n- D’artagnan',
          'composite': ''
        },
      ]
    },
    {
      'address': 13,
      'filename': '7 Grimm.jpg',
      'author': 'Grimm',
      'width': 750,
      'height': 564,
      'pairings': [
        {
          'pair': 12,
          'position': '',
          'description': 'Leavings and arrivings\n\nSunsets and sunrises\n\nRoads diverge and come back together\n\nSometimes we take an exit on the highway of life\n\nAnd sometimes we rejoin the stream of traffic\n\nAll reflected in the sky\n\nBefore the final vistas show themselves\n\n- D’artagnan',
          'composite': ''
        },
      ]
    },
    {
      'address': 14,
      'filename': '8 Dart.jpg',
      'author': 'D\'art',
      'width': 750,
      'height': 982,
      'pairings': [
        {
          'pair': 15,
          'position': '',
          'description': 'From the astronomical to the ground truth\n\nSkeletal electricity shines through\n\nConnects us in ways learned through our lives\n\nMore the same than different at third glance\n\n- D’artagnan',
          'composite': ''
        },
      ]
    },
    {
      'address': 15,
      'filename': '8 Grimm.jpg',
      'author': 'Grimm',
      'width': 750,
      'height': 998,
      'pairings': [
        {
          'pair': 14,
          'position': '',
          'description': 'From the astronomical to the ground truth\n\nSkeletal electricity shines through\n\nConnects us in ways learned through our lives\n\nMore the same than different at third glance\n\n- D’artagnan',
          'composite': ''
        },
        {
          'pair': 22,
          'position': '',
          'description': 'Both pictures are like a moonscape with the sun and the earth.\n\n-Ray',
          'composite': ''
        },
      ]
    },
    {
      'address': 16,
      'filename': '9 Ashley.png',
      'author': 'Ashley',
      'width': 1920,
      'height': 1080,
      'pairings': [
        {
          'pair': 17,
          'position': '',
          'description': 'Waking up to buckets of flowers dumped on my head\n\nBonding at its best\n\nLike a hug from your child\n\nMemories of a lifetime\n\n- D’artagnan',
          'composite': '9 D\'art and Ashley.jpg'
        },
      ]
    },
    {
      'address': 17,
      'filename': '9 Dart.JPG',
      'author': 'D\'art',
      'width': 2654,
      'height': 2163,
      'pairings': [
        {
          'pair': 16,
          'position': '',
          'description': 'Waking up to buckets of flowers dumped on my head\n\nBonding at its best\n\nLike a hug from your child\n\nMemories of a lifetime\n\n- D’artagnan',
          'composite': '9 Dart and Ashley.jpg'
        },
      ]
    },
    {
      'address': 18,
      'filename': '10 Dart.jpg',
      'author': 'D\'art',
      'width': 1500,
      'height': 2000,
      'pairings': [
        {
          'pair': 19,
          'position': '',
          'description': 'Waking up to buckets of flowers dumped on my head\n\nBonding at its best\n\nLike a hug from your child\n\nMemories of a lifetime\n\n- D’artagnan',
          'composite': ''
        },
      ]
    },
    {
      'address': 19,
      'filename': '10 Ray.jpg',
      'author': 'Ray',
      'width': 750,
      'height': 1000,
      'pairings': [
        {
          'pair': 18,
          'position': '',
          'description': 'Waking up to buckets of flowers dumped on my head\n\nBonding at its best\n\nLike a hug from your child\n\nMemories of a lifetime\n\n- D’artagnan',
          'composite': ''
        },
      ]
    },
    {
      'address': 20,
      'filename': '11 Dart.jpg',
      'author': 'D\'art',
      'width': 750,
      'height': 1751,
      'pairings': [
        {
          'pair': 21,
          'position': '',
          'description': '',
          'composite': ''
        },
      ]
    },
    {
      'address': 21,
      'filename': '11 Ray.jpg',
      'author': 'Ray',
      'width': 750,
      'height': 1000,
      'pairings': [
        {
          'pair': 20,
          'position': '',
          'description': '',
          'composite': ''
        },
      ]
    },
    {
      'address': 22,
      'filename': '12 Ray.jpg',
      'author': 'Ray',
      'width': 750,
      'height': 563,
      'pairings': [
        {
          'pair': 15,
          'position': '',
          'description': 'Both pictures are like a moonscape with the sun and the earth.\n\n-Ray',
          'composite': ''
        },
      ]
    },
    {
      'address': 23,
      'filename': '13 Grimm.jpg',
      'author': 'Grimm',
      'width': 1168,
      'height': 1560,
      'pairings': [
        {
          'pair': 24,
          'position': '',
          'description': 'These two are like color negatives of each other.\n\n-Ray',
          'composite': ''
        },
      ]
    },
    {
      'address': 24,
      'filename': '13 Ray.jpg',
      'author': 'Ray',
      'width': 1500,
      'height': 2000,
      'pairings': [
        {
          'pair': 23,
          'position': '',
          'description': 'These two are like color negatives of each other.\n\n-Ray',
          'composite': ''
        },
      ]
    },
    {
      'address': 25,
      'filename': '14 Grimm.jpg',
      'author': 'Grimm',
      'width': 750,
      'height': 1001,
      'pairings': [
        {
          'pair': 26,
          'position': '',
          'description': 'I was thinking about the orientation of drums [when I chose my image]. Now I need both images to do this!\n\n- Ray',
          'composite': ''
        },
      ]
    },
    {
      'address': 26,
      'filename': '14 Ray.jpg',
      'author': 'Ray',
      'width': 1875,
      'height': 2500,
      'pairings': [
        {
          'pair': 25,
          'position': '',
          'description': 'I was thinking about the orientation of drums [when I chose my image]. Now I need both images to do this!\n\n- Ray',
          'composite': ''
        },
      ]
    },
    {
      'address': 27,
      'filename': '15 Pat.jpg',
      'author': 'Pat',
      'width': 1831,
      'height': 3152,
      'pairings': [
        {
          'pair': 28,
          'position': '',
          'description': 'I see similar characters in both of these.\n\n- Ray',
          'composite': ''
        },
      ]
    },
    {
      'address': 28,
      'filename': '15 Ray.jpg',
      'author': 'Ray',
      'width': 1500,
      'height': 2000,
      'pairings': [
        {
          'pair': 27,
          'position': '',
          'description': 'I see similar characters in both of these.\n\n- Ray',
          'composite': ''
        },
      ]
    },
    {
      'address': 29,
      'filename': '16 Ray.jpg',
      'author': 'Ray',
      'width': 1000,
      'height': 1333,
      'pairings': [
        {
          'pair': 30,
          'position': '',
          'description': 'There will always be a moon over the bay.\n\n- D\'artagnan',
          'composite': ''
        },
      ]
    },
    {
      'address': 30,
      'filename': '16 Dart.jpg',
      'author': 'D\'art',
      'width': 1500,
      'height': 2000,
      'pairings': [
        {
          'pair': 29,
          'position': '',
          'description': 'There will always be a moon over the bay.\n\n- D\'artagnan',
          'composite': ''
        },
      ]
    },
    {
      'address': 31,
      'filename': '17 Ray.jpg',
      'author': 'Ray',
      'width': 1500,
      'height': 1504,
      'pairings': [
        {
          'pair': 32,
          'position': '',
          'description': '',
          'composite': ''
        },
      ]
    },
    {
      'address': 32,
      'filename': '17 Dart.jpg',
      'author': 'D\'art',
      'width': 1500,
      'height': 1125,
      'pairings': [
        {
          'pair': 31,
          'position': '',
          'description': '',
          'composite': ''
        },
      ]
    },
    {
      'address': 33,
      'filename': '18 Ashley.jpg',
      'author': 'Ashley',
      'width': 1000,
      'height': 1333,
      'pairings': [
        {
          'pair': 34,
          'position': 'left',
          'description': '“Did I remember to charge my prosthetics?”\n\n“…Oh my god I forgot my lower half. I hope nobody messes with it!”\n\n- Laura',
          'composite': ''
        },
      ]
    },
    {
      'address': 34,
      'filename': '18 Laura.jpg',
      'author': 'Laura',
      'width': 720,
      'height': 960,
      'pairings': [
        {
          'pair': 33,
          'position': 'right',
          'description': '“Did I remember to charge my prosthetics?”\n\n“…Oh my god I forgot my lower half. I hope nobody messes with it!”\n\n- Laura',
          'composite': ''
        },
      ]
    },
    {
      'address': 35,
      'filename': '19 Laura.jpg',
      'author': 'Laura',
      'width': 507,
      'height': 631,
      'pairings': [
        {
          'pair': 4,
          'position': '',
          'description': 'Color my feelings.\n\n- Laura',
          'composite': ''
        },
      ]
    },
    {
      'address': 36,
      'filename': '20 Pat.jpg',
      'author': 'Pat',
      'width': 750,
      'height': 563,
      'pairings': [
        {
          'pair': 37,
          'position': 'left',
          'description': 'Black or white, in or out, keep it to yourself, or shout it out.\n\n- Laura',
          'composite': ''
        },
      ]
    },
    {
      'address': 37,
      'filename': '20 Laura.jpg',
      'author': 'Laura',
      'width': 720,
      'height': 960,
      'pairings': [
        {
          'pair': 36,
          'position': 'right',
          'description': 'Black or white, in or out, keep it to yourself, or shout it out.\n\n- Laura',
          'composite': ''
        },
      ]
    },
    {
      'address': 38,
      'filename': '21 Pat.jpg',
      'author': 'Pat',
      'width': 1000,
      'height': 1333,
      'pairings': [
        {
          'pair': 39,
          'position': 'right',
          'description': 'When dogs have schizophrenia!\n\n- Laura',
          'composite': ''
        },
      ]
    },
    {
      'address': 39,
      'filename': '21 Laura.jpg',
      'author': 'Laura',
      'width': 750,
      'height': 872,
      'pairings': [
        {
          'pair': 38,
          'position': 'left',
          'description': 'When dogs have schizophrenia!\n\n- Laura',
          'composite': ''
        },
      ]
    },
    {
      'address': 40,
      'filename': '22 Grimm.JPG',
      'author': 'Grimm',
      'width': 1170,
      'height': 1560,
      'pairings': [
        {
          'pair': 41,
          'position': '',
          'description': 'Hot in thought.\n\n- Laura',
          'composite': '22 Laura and Grimm.jpg'
        },
      ]
    },
    {
      'address': 41,
      'filename': '22 Laura.jpg',
      'author': 'Laura',
      'width': 1240,
      'height': 1888,
      'pairings': [
        {
          'pair': 40,
          'position': '',
          'description': 'Hot in thought.\n\n- Laura',
          'composite': '22 Laura and Grimm.jpg'
        },
      ]
    },
    {
      'address': 42,
      'filename': '23 Pat.jpg',
      'author': 'Pat',
      'width': 4032,
      'height': 3024,
      'pairings': [
        {
          'pair': 43,
          'position': '',
          'description': 'This reminds me of sunset at Shellmound and 67th.\n\n- Pat',
          'composite': '23 Pat and Grimm.jpg'
        },
      ]
    },
    {
      'address': 43,
      'filename': '23 Grimm.JPG',
      'author': 'Grimm',
      'width': 1560,
      'height': 1170,
      'pairings': [
        {
          'pair': 42,
          'position': '',
          'description': 'This reminds me of sunset at Shellmound and 67th.\n\n- Pat',
          'composite': '23 Pat and Grimm.jpg'
        },
      ]
    },{
      'address': 44,
      'filename': '24 Pat.jpg',
      'author': 'Pat',
      'width': 852,
      'height': 480,
      'pairings': [
        {
          'pair': 45,
          'position': '',
          'description': 'Sometimes in the tents there is an animal kind of glow or a spirit-like fog over everything. You come home and there\'s a nice spiritual hue over everything. Everything almost feels like it has a sense of life to it, no matter how dark and dreary it is.\n\n- Pat',
          'composite': '24 pat and ashley.jpg'
        },
      ]
    },
    {
      'address': 45,
      'filename': '24 Ashley.png',
      'author': 'Ashley',
      'width': 1920,
      'height': 1080,
      'pairings': [
        {
          'pair': 44,
          'position': '',
          'description': 'Sometimes in the tents there is an animal kind of glow or a spirit-like fog over everything. You come home and there\'s a nice spiritual hue over everything. Everything almost feels like it has a sense of life to it, no matter how dark and dreary it is.\n\n- Pat',
          'composite': '24 pat and ashley.jpg'
        },
      ]
    },
  ]

export default images;