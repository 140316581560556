import { useState } from "react";
import { Link } from "react-router-dom";
import "./NavBar.css";
import { artistNames, artistText } from "./ArtistPageData";

const ArtistsMenu = (props) => {
  const { setCurrentPage, currentPage, setShowArtistsMenu } = props;
  return (
    <ul
      className={
        currentPage.includes("artists")
          ? "DropdownMenu currentPage"
          : "DropdownMenu"
      }
      onBlur={() => setShowArtistsMenu(false)}
    >
      {artistNames.map((artistName) => {
        const artistId = artistText[artistName].id;
        return (
          <li className="navBar-item-text">
            <Link
              className="navBar-item-text"
              style={{ zIndex: 3 }}
              to={`/artists/${artistId}`}
              onClick={() => {
                setCurrentPage(`artists/${artistId}`);
                setShowArtistsMenu(false);
              }}
            >
              <h2>{artistName}</h2>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

const MapsMenu = (props) => {
  const { setCurrentPage, currentPage, setShowMapsMenu } = props;
  return (
    <ul
      className={
        currentPage === "interactive-map" || currentPage === "pairings"
          ? "DropdownMenu currentPage"
          : "DropdownMenu"
      }
      onBlur={() => setShowMapsMenu(false)}
    >
      <li>
        <Link
          className="navBar-item-text"
          to={"/pairings"}
          onClick={() => {
            setCurrentPage("pairings");
            setShowMapsMenu(false);
          }}
        >
          <h2>Pairings Map</h2>
        </Link>
      </li>
      <li className="navBar-item-text">
        <Link
          className="navBar-item-text"
          to={"/interactive-map"}
          onClick={() => {
            setCurrentPage("interactive-map");
            setShowMapsMenu(false);
          }}
        >
          <h2>Interactive Map</h2>
        </Link>
      </li>
    </ul>
  );
};

const NavBar = (props) => {
  const { currentPage, setCurrentPage } = props;
  const [showArtistsMenu, setShowArtistsMenu] = useState(false);
  const [showMapsMenu, setShowMapsMenu] = useState(false);
  return (
    <nav>
      <ul className="navBar">
        <li
          className={
            currentPage === "home" ? "navBar-item currentPage" : "navBar-item"
          }
        >
          <Link
            to="/home"
            className="navBar-item-text"
            onClick={() => setCurrentPage("home")}
          >
            <h2>Home</h2>
          </Link>
        </li>

        <li
          className={
            currentPage === "project"
              ? "navBar-item currentPage"
              : "navBar-item"
          }
        >
          <Link
            to="/project"
            className="navBar-item-text"
            onClick={() => setCurrentPage("project")}
          >
            <h2>Project</h2>
          </Link>
        </li>

        <li
          className={
            currentPage.includes("artists")
              ? "navBar-item currentPage"
              : "navBar-item"
          }
          onClick={() => setShowArtistsMenu(!showArtistsMenu)}
          onMouseEnter={() => {
            setShowArtistsMenu(true);
          }}
          onMouseLeave={() => {
            setShowArtistsMenu(false);
          }}
        >
          <Link
            className="navBar-item-text"
            onClick={() => setCurrentPage("artists")}
          >
            {!showArtistsMenu ? <h2>Artists</h2> : <div />}
          </Link>

          {showArtistsMenu ? (
            <ArtistsMenu
              setCurrentPage={setCurrentPage}
              setShowArtistsMenu={setShowArtistsMenu}
              currentPage={currentPage}
            />
          ) : (
            <div />
          )}
        </li>

        <li
          className={
            currentPage === "pairings"
              ? "navBar-item currentPage"
              : "navBar-item"
          }
          onClick={() => setShowMapsMenu(!showMapsMenu)}
          onMouseEnter={() => {
            setShowMapsMenu(true);
          }}
          onMouseLeave={() => {
            setShowMapsMenu(false);
          }}
        >
          <Link
            to="/pairings"
            className="navBar-item-text"
            onClick={() => setCurrentPage("pairings")}
          >
            {!showMapsMenu ? <h2>Maps</h2> : <div />}
          </Link>

          {showMapsMenu ? (
            <MapsMenu
              setCurrentPage={setCurrentPage}
              setShowMapsMenu={setShowMapsMenu}
              currentPage={currentPage}
            />
          ) : (
            <div />
          )}
        </li>

        <li
          className={
            currentPage === "events" ? "navBar-item currentPage" : "navBar-item"
          }
        >
          <Link
            to="/events"
            className="navBar-item-text"
            onClick={() => setCurrentPage("events")}
          >
            <h2>Events</h2>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
