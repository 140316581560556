import "./PairingsPage.css";
import React, { useState } from "react";
import Modal from "react-modal";
import images from "./ImagesStore";
import Masonry from "react-masonry-component";

function calculateGroup(address, pairings) {
  const group = [address];
  for (let i = 0; i < pairings.length; i++) {
    group.push(pairings[i].pair);
  }
  return group;
}

function shuffle(array) {
  var m = array.length,
    t,
    i;
  while (m) {
    i = Math.floor(Math.random() * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }
  return array;
}

const shuffledImages = shuffle([...images]);
const groupsByAddress = images.map((row) =>
  calculateGroup(row.address, row.pairings)
);
class PairingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      hover: [],
      select: "",
      columnWidth: 9.825,
    };
  }

  setHover = (group) => {
    this.setState({ hover: group });
  };
  resetHover = () => {
    this.setState({ hover: [] });
  };
  setSelect = (address) => {
    this.setState({ select: address });
    this.resetHover();
  };
  resetSelect = () => {
    this.setState({ select: "" });
  };
  setShow = () => {
    this.setState({ show: true });
  };
  resetShow = () => {
    this.setState({ show: false });
  };
  setCurrentPage = (page) => {
    this.setState({ currentPage: page });
  };

  Block = (props) => {
    const { address, filename, myClass, myWrapperClass } = props;
    const group = groupsByAddress[address] ?? [];

    const handleClick = () => {
      this.setSelect(address);
      this.setShow(group);
    };

    return (
      <div className={myWrapperClass}>
        <img
          src={`${process.env.PUBLIC_URL}/images/paired-art-images/${filename}`}
          alt=""
          onMouseEnter={() => this.setHover(group)}
          onMouseOut={() => this.resetHover()}
          className={myClass}
          onClick={this.state.show ? () => {} : handleClick}
        />
      </div>
    );
  };

  LightBox = (props) => {
    const { address } = props;
    if (images[address])
      return (
        <div className="lightbox">
          {images[address].pairings.map((row) => (
            <this.LightBoxRow
              key={`${address}-${row.pair}`}
              address={address}
              pairing={row}
            />
          ))}
        </div>
      );
    else return <div />;
  };

  LightBoxRow = (props) => {
    const { address, pairing } = props;
    const [leftImage, rightImage] =
      pairing.position === "right"
        ? [pairing.pair, address]
        : [address, pairing.pair];
    if (pairing.composite) {
      return (
        <div className="lightbox-row">
          <div className="lightbox-row-images">
            <div className="lightbox-row-images-columns">
              <this.Block
                key={`${address}-${pairing.pair}-composite`}
                address="lightbox-composite"
                filename={pairing.composite}
                myWrapperClass={"image-wrapper lightbox-composite"}
                myClass={"block lightbox-block composite"}
              />
              <div className="description">
                {images[address].author} and {images[pairing.pair].author}
              </div>
            </div>
          </div>
          <div className="lightbox-row-description">
            <div className="description">{pairing.description}</div>
          </div>
        </div>
      );
    }
    return (
      <div className="lightbox-row">
        <div className="lightbox-row-images">
          <div className="lightbox-row-images-columns">
            <this.Block
              key={`${address}-${pairing.pair}-left`}
              address="lightbox-pair"
              filename={images[leftImage].filename}
              myWrapperClass={"image-wrapper lightbox-pair"}
              myClass={"block lightbox-block"}
            />
            <div className="description">{images[leftImage].author}</div>
          </div>
          <div className="lightbox-row-images-columns">
            <this.Block
              key={`${address}-${pairing.pair}-right`}
              address="lightbox-pair"
              filename={images[rightImage].filename}
              myWrapperClass={"image-wrapper lightbox-pair"}
              myClass={"block lightbox-block"}
            />
            <div className="description">{images[rightImage].author}</div>
          </div>
        </div>
        <div className="lightbox-row-description">
          <div className="description">{pairing.description}</div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="pairingsPageContainer">
        <h1 className="pairingsPageHeader">
          Ashby Shellmound Artwork Pairings
        </h1>
        <p className="pairings-map-page-text">
          Each participating artist selected work from their own portfolios and
          paired this work with an artwork from another person in the project.
          This creates an interconnected map of artworks and reveals a diverse
          web of relationships. While the reasons each artist had for pairing
          works were incredibly diverse, the process of creating this art “map”
          provided a way for the artists to stay connected with each other after
          eviction and reflect upon the value of the the Ashby/Shellmound
          encampment community and its impact upon their own creative processes.
        </p>
        <p className="pairings-map-page-text">
          Move the cursor over the images to view the relationships between
          artworks. Click on any image to enlarge and read the descriptio n
          written by each artist about the relationship. Refresh the page to
          shuffle the images and see the relationships anew
        </p>
        <div className="masonry-grid-wrapper">
          <Masonry className="masonry-grid">
            {shuffledImages.map((row) => (
              <this.Block
                key={row.address.toString()}
                address={row.address}
                filename={row.filename}
                myWrapperClass={
                  this.state.hover.includes(row.address)
                    ? "image-wrapper highlight"
                    : "image-wrapper"
                }
                myClass={
                  this.state.hover.includes(row.address)
                    ? "block highlight"
                    : "block"
                }
              />
            ))}
          </Masonry>
        </div>
        <Modal
          isOpen={this.state.show}
          onRequestClose={this.resetShow}
          ariaHideApp={false}
          style={{
            overlay: {},
            content: {
              padding: 0,
              position: "relative",
              width: "fit-content",
              height: "fit-content",
              overflow: "scroll",
              maxWidth: "70%",
              maxHeight: "90%", //for some reason, vw units breaks the scroll here, use %
              display: "flex",
              flexDirection: "column",
              backgroundColor: "black",
              borderRadius: "0%",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              overflowX: "clip", //does this fix the modal border bug?
            },
          }}
        >
          <div className="modal-header">
            <button
              className="modal-header-exit-button"
              onClick={() => {
                this.resetShow();
              }}
            >
              X
            </button>
          </div>
          <this.LightBox address={this.state.select} />
        </Modal>
      </div>
    );
  }
}

export default PairingsPage;
