const artistNames = [
  "Ashley Frankum",
  "D'artagnan “D'art” Lloyd",
  'Brandon "Grimm" Mercer',
  "John Benson",
  "Laura Berry",
  "Patrick Thomas",
  "Raymond Leichter",
  "Suzi Garner",
];

const artistText = {
  "Ashley Frankum": {
    bio: `Ashley Frankum's work is performative and playful, utilizing mannequins and other props which are
  hand painted and decorated to create scenes, as well as elaborate face paint and costume wear.Her
  intention is often to shock and create awareness in the viewer of how judgemental society can be.
  Originally from Mississippi, Ashley feels that Berkeley is home, and has been living and making art at
  Ashby/Shellmound on and off for over a year. \br
  
  Ashley was severely burned in a tent fire in the summer of 2021. She spent her time at a local hospital
  burn unit documenting her body's healing process with her camera. `,
    id: "Ashley",
  },
  "D'artagnan “D'art” Lloyd": {
    bio: `D'artagnan “D'art” Lloyd grew up in the San Francisco and Sonoma County areas. He was the art editor
  of his school newspaper and went on to be a patron of the arts at UC Berkeley, living in Barrington
  Hall. In addition to studying Geology at Stanford he has built art cars for Burning Man and been a
  street artist for the last ten years, focusing on kinetic sculpture made with found objects, mask making,
  and painting. He has lived off and on at the Ashby/Shellmound encampment for four years.\br
  
  Through his work D'art feels a connection to the place of Ashby/Shellmound, seeing it as a burial
  ground for many beings, spirits who have messages they wish to convey to the contemporary world. He
  feels his work plays a role in channeling and conveying these messages in some form.\br

  D'art hopes that The People are the Place can be connected to a kind of spiritual revolution, an
  evolution of all sentient beings which can contribute to reducing pain and suffering. He wants the
  positive energy of this project to expand, spark and spread like fire. `,
    id: "Dart",
  },
  'Brandon "Grimm" Mercer': {
    bio: `Brandon Mercer (AKA Grimm) was born in LA and has been living on and off at Ashby/Shellmound
for the last 20 years. Grimm takes photographs of his environment and often uses filters to change the
appearance and feeling of his subjects to match his interior view of the world. A longtime practitioner
of martial arts, Grimm is also a board member of Where Do We Go?, an advocacy organization in
support of the unhoused communities living at Ashby/Shellmound and Seabreeze encampments, and
has participated in many podcasts and media projects to advocate for the rights of the unhoused. `,
    id: "Grimm",
  },
  "John Benson": { bio: ``, id: "John" },
  "Laura Berry": { bio: ``, id: "Laura" },
  "Patrick Thomas": {
    bio: `Originally from the Washington DC area, Patrick Thomas draws and paints in a wide variety of scales
  and mediums. Living at Ashby/Shellmound Pat found the environment inspiring and supportive in
  making his work both larger and better, giving him the chance to explore different options. He sees The
  People are the Place as a collaborative project bringing the opportunity to to take the work to a bigger
  scale, connecting with the community at large, sharing his art and helping to raise awareness of the
  struggle which unhoused community members have to experience. `,
    id: "Pat",
  },
  "Raymond Leichter": {
    bio: `Ray Leichter grew up in Corconrd/Martinez and has lived in the East Bay for the last six years. He
  creates sculpture and percussive instruments using found objects from his environment. Stating that he
  finds Ashby/Shellmound to be his art medium, his percussive performances are created in response to
  the sounds within his environment, such as the trains and car traffic. He also makes sculptures out of
  found materials. He has lived at Ashby/Shellmound steadily for the last year and a half, and on and off
  for the last 3 years. \br

  Ray hopes that The People are the Place can be a channel for communication. He hopes not to over
  amplify or distort the creative work of himself and others in the project. `,
    id: "Ray",
  },
  "Suzi Garner": {
    bio: `Suzi Garner is a multi-media artist. Using recycled materials, light and shadow, and found or natural objects she collaborates with individuals, communities and the environment to create situations which are documented with photography, text, audio and video to create installations and performances. Current themes include amplifying the voices of unhoused community members and reflecting on the challenges of motherhood. `,
    website: `https://www.suzigarner.com/`,
    id: "Suzi",
  },
};

const artistImageFilenames = {
  Ashley: [
    "Ashley Untitled 1.jpg",
    "Ashley Untitled 10.JPG",
    "Ashley Untitled 11.JPG",
    "Ashley Untitled 12.JPG",
    "Ashley Untitled 13.JPG",
    "Ashley Untitled 14.JPG",
    "Ashley Untitled 15.jpg",
    "Ashley Untitled 16.jpg",
    "Ashley Untitled 17.JPG",
    "Ashley Untitled 18.jpg",
    "Ashley Untitled 19.png",
    "Ashley Untitled 2.jpg",
    "Ashley Untitled 20.png",
    "Ashley Untitled 21.JPG",
    "Ashley Untitled 22.JPG",
    "Ashley Untitled 23.JPG",
    "Ashley Untitled 24.JPG",
    "Ashley Untitled 25.JPG",
    "Ashley Untitled 26.JPG",
    "Ashley Untitled 27.JPG",
    "Ashley Untitled 28.JPG",
    "Ashley Untitled 29.JPG",
    "Ashley Untitled 3.JPG",
    "Ashley Untitled 4.JPG",
    "Ashley Untitled 5.JPG",
    "Ashley Untitled 6.JPG",
    "Ashley Untitled 7.JPG",
    "Ashley Untitled 8.JPG",
    "Ashley Untitled 9.JPG",
  ],
  Dart: [
    "Dart Untitled 1.jpg",
    "Dart Untitled 10.JPG",
    "Dart Untitled 11.JPG",
    "Dart Untitled 12.JPG",
    "Dart Untitled 13.JPG",
    "Dart Untitled 14.JPG",
    "Dart Untitled 15.JPG",
    "Dart Untitled 16.JPG",
    "Dart Untitled 17.JPG",
    "Dart Untitled 18.jpg",
    "Dart Untitled 19.JPG",
    "Dart Untitled 2.jpg",
    "Dart Untitled 20.JPG",
    "Dart Untitled 3.jpg",
    "Dart Untitled 4.jpg",
    "Dart Untitled 5.jpg",
    "Dart Untitled 6.JPG",
    "Dart Untitled 7.JPG",
    "Dart Untitled 8.JPG",
    "Dart Untitled 9.JPG",
  ],
  John: [
    "6063695235_2c19722fe5_o.jpg",
    "vibrating milk on a drum head.PNG",
    "Cymatic patterns in milk on a drum head.PNG",
  ],
  Grimm: [
    "Grimm 1.JPG",
    "Grimm Artwork for Website",
    "IMG_2584~photo.JPG",
    "IMG_2591~photo.JPG",
    "IMG_2594~photo.JPG",
    "IMG_2599~photo.JPG",
    "IMG_2601~photo.JPG",
    "IMG_2602~photo.JPG",
    "IMG_2604~photo.JPG",
    "IMG_2607~photo.JPG",
    "IMG_2608~photo.JPG",
    "IMG_2610~photo.JPG",
    "IMG_2611~photo.JPG",
    "IMG_2613~photo.JPG",
    "IMG_2617~photo.JPG",
    "IMG_2619~photo.JPG",
    "IMG_2620~photo.JPG",
    "IMG_2621~photo.JPG",
    "IMG_2622~photo.JPG",
    "IMG_2623~photo.JPG",
    "IMG_2625~photo.JPG",
    "IMG_2627~photo.JPG",
    "IMG_2633~photo.JPG",
    "IMG_2634~photo.JPG",
    "IMG_2635~photo.JPG",
    "IMG_2636~photo.JPG",
    "IMG_2637~photo.JPG",
    "IMG_2639~photo.JPG",
    "IMG_2640~photo.JPG",
    "IMG_2641~photo.JPG",
    "IMG_2642~photo.JPG",
    "IMG_2645~photo.JPG",
    "IMG_2664~photo.JPG",
    "IMG_2665~photo.JPG",
    "IMG_2666~photo.JPG",
    "IMG_2667~photo.JPG",
    "IMG_2668~photo.JPG",
    "IMG_2669~photo.JPG",
    "IMG_2670~photo.JPG",
    "IMG_2671~photo.JPG",
    "IMG_2674~photo.JPG",
    "IMG_2675~photo.JPG",
    "IMG_2676~photo.JPG",
    "IMG_2680~photo.JPG",
    "IMG_2681~photo.JPG",
    "IMG_2682~photo.JPG",
    "IMG_2683~photo.JPG",
    "IMG_2684~photo.JPG",
    "IMG_2685~photo.JPG",
    "IMG_2686~photo.JPG",
    "IMG_2687~photo.JPG",
    "IMG_2689~photo.JPG",
    "IMG_2690~photo.JPG",
    "IMG_2692~photo.JPG",
    "IMG_2693~photo.JPG",
    "IMG_2694~photo.JPG",
    "IMG_2695~photo.JPG",
    "IMG_2696~photo.JPG",
    "IMG_2700~photo.JPG",
    "IMG_2701~photo.JPG",
    "IMG_2705~photo.JPG",
    "IMG_2706~photo.JPG",
    "IMG_2707~photo.JPG",
    "IMG_2733~photo.JPG",
    "IMG_2734~photo.JPG",
    "IMG_2735~photo.JPG",
    "IMG_2736~photo.JPG",
    "IMG_2794~photo.JPG",
    "IMG_4587~photo.JPG",
    "IMG_4588~photo.JPG",
    "IMG_4589~photo.JPG",
    "IMG_4593~photo.JPG",
    "IMG_4595~photo.JPG",
    "IMG_4596~photo.JPG",
    "IMG_4597~photo.JPG",
    "IMG_4619~photo.JPG",
    "IMG_4620~photo.JPG",
    "IMG_4621~photo.JPG",
    "IMG_4636~video.mov",
    "IMG_4637~photo.JPG",
    "IMG_4638~photo.JPG",
    "IMG_4639~photo.JPG",
    "IMG_4640~photo.JPG",
    "IMG_4641~photo.JPG",
    "IMG_4645~photo.JPG",
    "IMG_4649~photo.JPG",
    "IMG_4658~photo.JPG",
    "IMG_4662~photo.JPG",
    "IMG_4664~photo.JPG",
    "IMG_4666~photo.JPG",
    "IMG_4670~photo.JPG",
    "IMG_4677~photo.JPG",
    "IMG_4679~photo.JPG",
    "IMG_4680~photo.JPG",
    "IMG_4684~photo.JPG",
    "IMG_4685~photo.JPG",
    "IMG_4686~photo.JPG",
    "IMG_4687~photo.JPG",
    "IMG_4688~photo.JPG",
    "IMG_4689~photo.JPG",
    "IMG_4909~photo.JPG",
    "IMG_4910~photo.JPG",
    "IMG_4930~photo.JPG",
    "IMG_4931~photo.JPG",
    "IMG_4935~photo.JPG",
    "IMG_4936~photo.JPG",
    "IMG_4937~photo.JPG",
    "IMG_4938~photo.JPG",
    "IMG_4939~photo.JPG",
    "IMG_4940~photo.JPG",
    "IMG_4941~photo.JPG",
    "IMG_4945~photo.JPG",
    "IMG_4956~photo.JPG",
    "IMG_4972~photo.JPG",
    "IMG_4977~photo.JPG",
    "IMG_4983~photo.JPG",
    "IMG_4988~photo.JPG",
    "IMG_4992~photo.JPG",
    "IMG_4994~photo.JPG",
    "IMG_4996~photo.JPG",
    "IMG_4997~photo.JPG",
    "IMG_4998~photo.JPG",
    "IMG_4999~photo.JPG",
    "IMG_5001~photo.JPG",
    "IMG_5002~photo.JPG",
    "IMG_5003~photo.JPG",
    "IMG_5004~photo.JPG",
    "IMG_5005~photo.JPG",
    "IMG_5006~photo.JPG",
    "IMG_5007~photo.JPG",
    "IMG_5008~photo.JPG",
    "IMG_5009~photo.JPG",
    "IMG_5010~photo.JPG",
    "IMG_5011~photo.JPG",
    "IMG_5012~photo.JPG",
    "IMG_5013~photo.JPG",
    "IMG_5015~photo.JPG",
  ],
  Laura: [
    "1 Laura left.JPG",
    "18 Laura.jpg",
    "19 Laura.jpg",
    "20 Laura.jpg",
    "20211020_143926.jpg",
    "21 Laura.jpg",
    "CYMERA_20211020_151612.jpg",
    "IMG_4514~photo.JPG",
    "IMG_4515~photo.JPG",
    "IMG_4516~photo.JPG",
    "IMG_4517~photo.JPG",
    "IMG_4518~photo.JPG",
    "IMG_4519~photo.JPG",
    "IMG_4522~photo.JPG",
    "IMG_4525~photo.JPG",
    "IMG_4527~photo.JPG",
    "IMG_4528~photo.JPG",
    "IMG_4533~photo.JPG",
    "IMG_4534~photo.JPG",
    "IMG_4535~photo.JPG",
    "IMG_4536~photo.JPG",
    "IMG_4538~photo.JPG",
    "IMG_4540~photo.JPG",
    "IMG_4541~photo.JPG",
    "IMG_4543~photo.JPG",
    "IMG_4545~photo.JPG",
    "IMG_4547~photo.JPG",
    "IMG_4549~photo.JPG",
    "IMG_4551~photo.JPG",
    "IMG_4552~photo.JPG",
    "IMG_4553~photo.JPG",
    "IMG_4554~photo.JPG",
    "IMG_4556~photo.JPG",
    "IMG_4557~photo.JPG",
    "IMG_4558~photo.JPG",
    "IMG_4559~photo.JPG",
    "IMG_4560~photo.JPG",
    "IMG_4561~photo.JPG",
    "IMG_4563~photo.JPG",
    "IMG_4564~photo.JPG",
    "IMG_4570~photo.JPG",
  ],
  Pat: [
    "23 Pat.jpg",
    "24 Pat.jpg",
    "IMG_0211.jpg",
    "IMG_2568~photo.JPG",
    "IMG_2769~photo.JPG",
    "IMG_2772~photo(1).JPG",
    "IMG_2774~photo.JPG",
    "IMG_2776~photo.JPG",
    "IMG_2778~adjustment.plist",
    "IMG_2778~photo-full.jpg",
    "IMG_2779~photo.JPG",
    "IMG_2780~adjustment.plist",
    "IMG_2780~photo-full.jpg",
    "IMG_2781~photo.JPG",
    "IMG_2948~photo.JPG",
    "IMG_2949~photo.JPG",
    "IMG_2950~photo.JPG",
    "IMG_2955~photo.JPG",
    "IMG_2957~photo.JPG",
    "IMG_2958~photo.JPG",
    "IMG_2961~photo.JPG",
    "IMG_2962~photo.JPG",
    "IMG_2963~photo.JPG",
    "IMG_2964~photo.JPG",
    "IMG_2966~photo.JPG",
    "IMG_3077~photo.JPG",
    "IMG_3078~photo.JPG",
    "IMG_4598~photo.JPG",
    "IMG_4600~photo.JPG",
    "IMG_4601~photo.JPG",
  ],
  Ray: [
    "IMG_4777~photo-full.jpg",
    "IMG_4778~photo-full.jpg",
    "IMG_4781~photo-full.jpg",
    "IMG_4782~photo-full.jpg",
    "IMG_4783~photo-full.jpg",
    "IMG_4784~photo-full.jpg",
    "IMG_4785~photo-full.jpg",
    "IMG_4786~photo-full.jpg",
    "IMG_4787~photo.JPG",
    "IMG_4789~photo-full.jpg",
    "IMG_4791~photo-full.jpg",
    "IMG_4794~photo-full.jpg",
    "IMG_4796~photo-full.jpg",
    "IMG_4800~photo-full.jpg",
    "IMG_4801~photo-full.jpg",
    "IMG_4803~photo-full.jpg",
    "IMG_4806~photo-full.jpg",
    "IMG_4809~photo.JPG",
    "IMG_4813~photo-full.jpg",
    "IMG_4817~photo-full.jpg",
    "IMG_4818~photo-full.jpg",
    "IMG_4823~photo-full.jpg",
    "IMG_4825~photo-full.jpg",
    "IMG_4826~photo-full.jpg",
    "IMG_4827~photo-full.jpg",
    "IMG_4830~photo-full.jpg",
    "IMG_4832~photo-full.jpg",
    "IMG_4833~photo-full.jpg",
    "IMG_4835~photo-full.jpg",
    "IMG_4840~photo-full.jpg",
  ],
  Suzi: [
    "IMG_1932.JPG",
    "IMG_5990.jpg",
    "IMG_6874.JPG",
    "IMG_6921.jpg",
    "IMG_9196.JPG",
    "Photo Mar 16, 7 37 25 PMweb.jpg",
    "hiding 1 web.jpg",
    "milk fountain 3 web.jpg",
    "milk moon 1 web.jpg",
    "motherhood 1 web.jpg",
  ],
};

export { artistText, artistImageFilenames, artistNames };
