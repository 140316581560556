import ReactPlayer from "react-player";
import "./Events.css";
import "./HomePage.css";

const Events = () => {
  return (
    <div>
      <div className="events-content">
        <h1 className="home-page-header">Events</h1>
        <h2 className="events-section-header"> Installation Video </h2>
        <p className="events-text">
          {" "}
          Multi Media Installation with sculpture by Dartagnan Lloyd,
          projections by Suzi Garner, with words and art by Ashley Frankum,
          Laura Berry, Pat Thomas, Ray Leichter, Grimm, and Dartagnan Lloyd. Art
          direction by Suzi Garner
        </p>
        <ReactPlayer
          className="video-player"
          url="https://www.youtube.com/watch?app=desktop&v=3XVOeDcPf9Q"
          width="70vw"
        />
        <h2 className="events-section-header">
          {" "}
          Multi Media Installation Feb 19 2022 7 - 9 PM{" "}
        </h2>
        <p className="events-text">
          {" "}
          Martin Luther King Civic Center Park, Berkeley This immersive
          installation will feature 360 degree video projections of films
          created using artwork of all six artists and footage of the Ashby
          Shellmoun d encampment, large scale kinetic sculptures by D'Artagnan
          Lloyd, and 4 channel audio installation incorporating percussion by
          Ray Leichter, the sounds of the encampment as well as the voices of
          all six artists speaking about their experiences.{" "}
        </p>
        <ReactPlayer
          className="video-player"
          url="https://www.youtube.com/watch?v=L6QpbwpiNuE"
          width="70vw"
        />
        <h2 className="events-section-header">
          {" "}
          Street Spirit Newspaper March 2022 Edition{" "}
        </h2>
        <p className="events-text">
          This edition of Street Spirit will be wholly devoted to publishing the
          extensive hand drawn maps and interviews of six encampment residents
          sharing their experiences of living at Ashby Shellmound and how the
          environment around the encampment impacted their creativity and
          community.
        </p>
        <h2 className="events-section-header"> Downtown Berkeley Projection</h2>
        <ReactPlayer
          width="70vw"
          className="video-player"
          url="https://www.youtube.com/watch?v=whykKYlBUJU&ab_channel=SuziGarner"
        />
      </div>
    </div>
  );
};

export default Events;
