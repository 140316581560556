import "./ArtistPage.css";
import images from "./ImagesStore";
// import Masonry from "react-masonry-component";
import Modal from "react-modal";
import { useState } from "react";
import { artistText, artistImageFilenames } from "./ArtistPageData.js";
import "./App.css";
import ReactPlayer from "react-player";


// const fs = require("fs");

const groupBy = (arr, criteria) => {
  const newObj = arr.reduce(function (acc, currentValue) {
    if (!acc[currentValue[criteria]]) {
      acc[currentValue[criteria]] = [];
    }
    acc[currentValue[criteria]].push(currentValue);
    return acc;
  }, {});
  return newObj;
};

const groupedImages = groupBy(images, "author");
console.log("grouped images: ", groupedImages);

const artistNames = Object.keys(artistText);

const GrimmVideos = () =>
  <div>
    <ReactPlayer
      className='react-player fixed-bottom'
      src={`${process.env.PUBLIC_URL}/images/Grimm-video.mov`}
      width='100%'
      height='100%'
      controls={true}

    />
  </div>


const ArtistImageModal = (props) => {
  const {
    artistImageModalIsShown,
    setArtistImageModalIsShown,
    artistId,
    imageFileName,
    setArtistAndFileNameToShow
  } = props;
  const imageFileNames = artistImageFilenames[artistId] || [];
  const showNext = (e) => {
    console.log("reached showNext");
    e.stopPropagation();
    let currentIndex = imageFileNames.indexOf(imageFileName);
    console.log("current index: ", currentIndex);
    if (currentIndex >= imageFileNames.length - 1) {
      setArtistImageModalIsShown(false);
    } else {
      let nextImage = imageFileNames[currentIndex + 1];
      setArtistAndFileNameToShow([artistId, nextImage]);
    }
  };
  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = imageFileNames.indexOf(imageFileName);
    if (currentIndex <= 0) {
      setArtistImageModalIsShown(false);
    } else {
      let nextImage = imageFileNames[currentIndex - 1];
      setArtistAndFileNameToShow([artistId, nextImage]);
    }
  };
  // const localImageURL = `${process.env.PUBLIC_URL}/images/Artist Folders/${artistId}/${imageFileName}`
  var s3ImageUrl = `https://ashby-shellmound-media.s3.us-west-2.amazonaws.com/images/Artist+Folders/${artistId}/${imageFileName}`.replace(' ', '+');;
  return (
    <Modal
      className="artist-page-lightbox"
      isOpen={artistImageModalIsShown}
      onRequestClose={() => setArtistImageModalIsShown(false)}
      ariaHideApp={false}
      onBlur= {() => setArtistImageModalIsShown(false)}
    >
      <button onClick={showPrev} className='image-button prev-image-button'>⭠</button>
      <img
        src={s3ImageUrl}
        alt=""
        className="artist-page-modal-image"
      />
      <button onClick={showNext} className='image-button next-image-button'>⭢</button>
    </Modal>
  );
};

const ArtistImageBlock = (props) => {
  const {
    artistId,
    imageFileName,
    artistImageModalIsShown,
    setArtistImageModalIsShown,
    setArtistAndFileNameToShow,
  } = props;
  const localImageURL = `${process.env.PUBLIC_URL}/images/Artist Folders/${artistId}/${imageFileName}`;
  var s3ImageUrl = `https://ashby-shellmound-media.s3.us-west-2.amazonaws.com/images/Artist+Folders/${artistId}/${imageFileName}`.replace(' ', '+');;
  console.log(localImageURL);
  console.log(s3ImageUrl);
  return (
    // <div className='image-container'>
    <div>
      <img
        src={s3ImageUrl}
        alt=""
        className="image"
        onClick={() => {
          setArtistImageModalIsShown(!artistImageModalIsShown);
          setArtistAndFileNameToShow([artistId, imageFileName]);
        }}
      />
      {/* <ArtistImageModal
        artistImageModalIsShown={artistImageModalIsShown}
        setArtistImageModalIsShown={setArtistImageModalIsShown}
        artistId={artistId}
        imageFileName={imageFileName}
      /> */}
    </div>
    // </div>
  );
};

const ArtistImageGrid = (props) => {
  const {
    artistId,
    artistImageModalIsShown,
    setArtistImageModalIsShown,
    setArtistAndFileNameToShow,
  } = props;
  console.log(artistId);
  const imageFileNames = artistImageFilenames[artistId] || [];
  console.log(imageFileNames)
  return (
    <div className="image-grid">
      {imageFileNames.map((imageFileName) => {
        console.log(imageFileName);
        return (
          <ArtistImageBlock
            imageFileName={imageFileName}
            artistId={artistId}
            artistImageModalIsShown={artistImageModalIsShown}
            setArtistImageModalIsShown={setArtistImageModalIsShown}
            setArtistAndFileNameToShow={setArtistAndFileNameToShow}
          />
        );
      })}
    </div>
  );
};
// }

const ArtistInfo = (
  name,
  artistImageModalIsShown,
  setArtistImageModalIsShown,
  setArtistAndFileNameToShow
) => {
  const bio = artistText[name].bio;
  const artistId = artistText[name].id;
  const website = artistText[name]?.website;

  return (
    <div className="artist-info">
      <div className="artist-header">
        <div className="artist-title">
          {name}
        </div>
        <a
          className="artist-link"
          href={website}
          target="_blank"
          rel="noreferrer"
        >
          {website}
        </a>
      </div>
      <div className="artist-text">
        {bio.split("\br").map((paragraph) => {
          return <p>{paragraph}</p>;
        })}
      </div>
      {/* {JSON.stringify(artistImages)} */}
      {/* {artistImages.length} */}
      <ArtistImageGrid
        artistId={artistId}
        artistImageModalIsShown={artistImageModalIsShown}
        setArtistImageModalIsShown={setArtistImageModalIsShown}
        setArtistAndFileNameToShow={setArtistAndFileNameToShow}
      />
      {artistId === 'Grimm' ? <GrimmVideos /> : <div></div>}
    </div >
  );
};

const ArtistsPage = () => {
  const [artistImageModalIsShown, setArtistImageModalIsShown] = useState(false);
  const [artistAndFileNameToShow, setArtistAndFileNameToShow] = useState([
    "",
    "",
  ]);

  return (
    <div className="artists-page">
      <h1 className="section-header">About The Artists</h1>
      <div className="artist-content">
        {artistNames.map((name) =>
          ArtistInfo(
            name,
            artistImageModalIsShown,
            setArtistImageModalIsShown,
            setArtistAndFileNameToShow
          )
        )}
      </div>
      <ArtistImageModal
        artistImageModalIsShown={artistImageModalIsShown}
        setArtistImageModalIsShown={setArtistImageModalIsShown}
        artistId={artistAndFileNameToShow[0]}
        imageFileName={artistAndFileNameToShow[1]}
        setArtistAndFileNameToShow={setArtistAndFileNameToShow}
      />
    </div>
  );
};

const AshleyPage = () => {
  const [artistImageModalIsShown, setArtistImageModalIsShown] = useState(false);
  const [artistAndFileNameToShow, setArtistAndFileNameToShow] = useState([
    "",
    "",
  ]);

  return (
    <div className="artists-page">
      <h1 className="section-header">About The Artists</h1>
      <div className="artist-content">
        {ArtistInfo(
          "Ashley Frankum",
          artistImageModalIsShown,
          setArtistImageModalIsShown,
          setArtistAndFileNameToShow
        )}

      </div>
      <ArtistImageModal
        artistImageModalIsShown={artistImageModalIsShown}
        setArtistImageModalIsShown={setArtistImageModalIsShown}
        artistId={artistAndFileNameToShow[0]}
        imageFileName={artistAndFileNameToShow[1]}
        setArtistAndFileNameToShow={setArtistAndFileNameToShow}
      />
    </div>
  );
};

const SingleArtistPage = (props) => {
  const { name } = props;
  const [artistImageModalIsShown, setArtistImageModalIsShown] = useState(false);
  const [artistAndFileNameToShow, setArtistAndFileNameToShow] = useState([
    "",
    "",
  ]);

  return (
    <div className="artists-page">
      <h1 className="section-header">About The Artists</h1>
      <div className="artist-content">
        {ArtistInfo(
          name,
          artistImageModalIsShown,
          setArtistImageModalIsShown,
          setArtistAndFileNameToShow
        )}

      </div>
      <ArtistImageModal
        artistImageModalIsShown={artistImageModalIsShown}
        setArtistImageModalIsShown={setArtistImageModalIsShown}
        artistId={artistAndFileNameToShow[0]}
        imageFileName={artistAndFileNameToShow[1]}
        setArtistAndFileNameToShow={setArtistAndFileNameToShow}
      />
    </div>
  );
};

export { ArtistsPage, AshleyPage, SingleArtistPage };
