import "./App.css";
import React, { useState } from "react";
import PairingsPage from "./PairingsPage";
import HomePage from "./HomePage";
import { SingleArtistPage } from "./ArtistsPage";
import Events from "./Events";
import { artistNames, artistText } from "./ArtistPageData";
import Banner from "./Banner";
import NavBar from "./NavBar";
import Slideshow from "./Slideshow";
import InteractiveMap from "./InteractiveMap";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import { useEffect } from "react/cjs/react.development";



const App = () => {

  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem("currentPage") || "home"
  );
  useEffect(() => {
    sessionStorage.setItem("currentPage", currentPage);
  }, [currentPage]);
  

  useEffect(() => {
    document.title = "The People Are The Place";
  }, []);

  return (
    <div className="appContainer">

      {(currentPage === "home") && <Banner />}

      <Router>
        <NavBar 
          currentPage={currentPage} 
          setCurrentPage={setCurrentPage} />
        <Switch>

          <Route 
            exact={true}
            path="/" 
            >
            <Redirect to="/home"/>
          </Route>
          <Route 
            exact={true}
            path="/ashby-shellmound" 
            >
            <Redirect to="/home"/>
          </Route>
          <Route 
            exact={true}
            path="/artists" 
            >
            <Redirect to="/artists/Ashley"/>
          </Route>

          <Route path="/home">
            <Slideshow />
          </Route>
          <Route path="/project">
            <HomePage />
          </Route>
          {artistNames.map((artistName) => {
            const artistId = artistText[artistName].id;
            return (
              <Route path={`/artists/${artistId}`}>
                <SingleArtistPage name={artistName} />
              </Route>
            );
          })}
          <Route path="/pairings">
            <PairingsPage />
          </Route>
          <Route path="/interactive-map">
            <InteractiveMap />
          </Route>
          <Route path="/events">
            <Events />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
