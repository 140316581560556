import './Banner.css'

const Banner = (props) => {
    return (
      <div className="header">
        {/* <h1>The People Are The Place</h1> */}
        <img
          src={`${process.env.PUBLIC_URL}/images/title-image.png`}
          alt="The People Are The Place"
          className="title-image"
        />
        <h6 className="title-description">
          A collaborative inquiry into place making, creative expression, and
          displacement
        </h6>
      </div>
    );
  };

export default Banner;