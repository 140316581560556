import Overlay from "react-image-overlay";
import React, { useLayoutEffect, useState } from "react";
import "./InteractiveMap.css";
import "./HomePage.css";
import ReactSlider from "react-slider";

const xRatio = 62 / 772.727272727;
const yRatio = 40.1176471 / 500;

const overlayImagesRootRelativePath =
  "/images/image-overlays/Interactive-Drawn-Map-Files/";
const filenames = [
  "Background inverted google map.png",
  // "Ashley Painted Map Full Opacity.png",
  // "Google Map Backdrop.png",
  // "Ashley Painted Map Low Opacity.png",
  "Grimm Map Colored.png",
  "Ashley Text Map Colored.png",
  "Laura Map Colored.png",
  // "Dart Painted Map Full Opacity.png",
  "Pat Map Colored.png",
  // "Dart Painted Map Low Opacity.png",
  "Ray Map Colored.png",
  "Dart Written Map Colored.png",
];

const pinIconFilenames = [
  "Ashley pins.png",
  "Dart pins.png",
  "Grimm pins.png",
  "Laura pins.png",
  "Pat pins.png",
  "Ray pins.png",
];

const filenamesToPinIconFilenames = {
  "Grimm Map Colored.png": "Grimm pins.png",
  "Ashley Text Map Colored.png": "Ashley pins.png",
  "Laura Map Colored.png": "Laura pins.png",
  "Pat Map Colored.png": "Pat pins.png",
  "Ray Map Colored.png": "Ray pins.png",
  "Dart Written Map Colored.png": "Dart pins.png",
};

const filenamesToColors = {
  "Grimm Map Colored.png": "#00f817",
  "Ashley Text Map Colored.png": "#f82b00",
  "Laura Map Colored.png": "#a800f8",
  "Pat Map Colored.png": "#f80096",
  "Ray Map Colored.png": "#005df8",
  "Dart Written Map Colored.png": "#f86d00",
};

const imagesForPopupsRootRelativePath =
  "/images/image-overlays/images-for-popups/";

const imagesForPinsRootRelativePath = "/images/pin-icons/";

const belowOverlayImagesRootRelativePath =
  "/images/image-overlays/images-for-below-map/";
const belowOverlayImageFilenames = [
  "Ashley Painted Map Full Opacity.png",
  "Dart Painted Map Full Opacity.png",
];
const belowOverlayCaptions = {
  "Ashley Painted Map Full Opacity.png":
    "Ashley Frankum. Personal Map of the Ashby/Shellmound Encampment Area with Biking Paths, Location of Tent and Friends' Tents, and a Pacman Ghost. Colored Pencil and Paint Markers on Vellum, 2021",
  "Dart Painted Map Full Opacity.png":
    "Dartagnan Lloyd. Personal Map of the Ashby/Shellmound Encampment Area with Freeway Exit, Train Route, Shoreline, and Poisoned Lake. Acrylic and marker on Vellum, 2021.",
};

const pinsData = {
  "Ashley Text Map Colored.png": {
    1: {
      location: { x: 570, y: 80 },
      text: "This is where I take my dog to play up at Aquatic Park.",
      image: "ashley-popup-1.jpg",
    },
    2: {
      location: { x: 650, y: 140 },
      text: "A dangerous place to be in at night. Especially for a woman.",
    },
    3: {
      location: { x: 370, y: 170 },
      text: "I can't tell you how many times I've ridden my bike through this area and up to seabreeze.",
    },
    4: {
      location: { x: 450, y: 320 },
      text: "Where my bike got smashed.",
    },
    5: {
      location: { x: 80, y: 300 },
      text: "Best view ever.",
    },
    6: {
      location: { x: 100, y: 400 },
      text: "Where my tent is. This is where I was burned but nothing else around me burned.",
    },
  },
  "Dart Written Map Colored.png": {
    1: {
      location: { x: 620, y: 240 },
      text: "My latest spot.",
    },
    2: {
      location: { x: 670, y: 170 },
      text: "Trains going by like a telegraph - graffiti messages from across the country.",
      // image: "", ADD THIS IMAGE
    },
    3: {
      location: { x: 550, y: 160 },
      text: "Dancing flying people sometimes appear here soaring through the air.",
    },
    4: {
      location: { x: 400, y: 420 },
      text: "I lived here in 2017 after I got shot.",
    },
    5: {
      location: { x: 300, y: 50 },
      text: "Smelly poisoned water.",
    },
    6: {
      location: { x: 145, y: 90 },
      text: "Hard to describe-related to underground power/energy sources that follow the direction of the freeway.",
    },
    7: {
      location: { x: 40, y: 170 },
      text: "Historical location of magical art.",
    },
    8: {
      location: { x: 30, y: 325 },
      text: "My transit route from Berkeley to Emeryville.",
    },
  },
  "Laura Map Colored.png": {
    1: {
      location: { x: 700, y: 120 },
      text: "Location of Laura's first tent at Ashby Shellmound Encampment.",
    },
    2: {
      location: { x: 580, y: 90 },
      text: "Common location for hookups in the bushes.",
    },
    3: {
      location: { x: 570, y: 270 },
      text: "Location of a variety of tents lived in by Laura over the course of three years at Ashby Shellmound encampment.",
    },
    4: {
      location: { x: 650, y: 420 },
      text: "Laura's most commonly used bike route.",
    },
  },
  "Grimm Map Colored.png": {
    1: {
      location: { x: 150, y: 5 },
      text: "Eddie Serafina jumped off the bridge at University Ave and was hit by two diesel trucks and died. I miss Eddie a lot I wish I had been there for him more.",
    },
    2: {
      location: { x: 450, y: 10 },
      text: "I like to go over to the lake late at night its all by itself and not so drowned out by the freeway.",
    },
    3: {
      location: { x: 95, y: 120 },
      text: "I go over there to talk to my dad sometimes...when he died he had his ashes thrown out to sea.",
    },
    4: {
      location: { x: 280, y: 200 },
      text: "This is the camp of a cross dresser who was dating himself. During the day time he was this guy and he's cleaning up his place fo rhis girlfriend cause she's such a bitch and she's gonna come home later. She doesn't want anyone ther eso we leave, but then at night he dresses up as the girl and she's looking for her boyfriend who's never around but is always off partying somewhere.",
    },
    5: {
      location: { x: 350, y: 300 },
      text: "Fire at my camp 2021",
      image: "grimm-popup-fire.jpg",
    },
    6: {
      location: { x: 450, y: 260 },
      text: "I inherited this spot 20 years ago from Sarah. There was a huge tree and network of trees and there was a cave burrowed out and a living area and a bar. In 2000 CalTrans knocked them out and they gave it to me.",
      image: "grimm-popup-bag.jpg",
    },
    7: {
      location: { x: 600, y: 270 },
      text: "Lost graffiti art from inside the building they knocked down.",
      image: "grimm-popup-graffiti.jpg",
    },
  },
  "Pat Map Colored.png": {
    1: {
      location: { x: 230, y: 40 },
      text: "My work area (flying a sign).",
      image: "pat-popup-sign.jpg",
    },
    2: {
      location: { x: 330, y: 60 },
      text: "A blue heron lives here.",
    },
    3: {
      location: { x: 400, y: 100 },
      text: "An egret lives here. Also a lot of ducks hang out here.",
    },
    4: {
      location: { x: 550, y: 70 },
      text: "My art walk. I walk up and down here to get inspiration.",
    },
    5: {
      location: { x: 620, y: 100 },
      text: "One of my hot spots for playing music. I play here the best.",
    },
    6: {
      location: { x: 600, y: 220 },
      text: "Right here has been my construction area.",
      image: "pat-popup-construction.jpg",
    },
    7: {
      location: { x: 380, y: 320 },
      text: "Play area with Grimm.",
    },
    8: {
      location: { x: 460, y: 260 },
      text: "My scout spot. From here I can see the whole area.",
    },
    9: {
      location: { x: 520, y: 250 },
      text: "Big murders of crows come and hang out in these spots.",
    },
    10: {
      location: { x: 620, y: 300 },
      text: "Big murders of crows come and hang out in these spots.",
    },
    11: {
      location: { x: 720, y: 270 },
      text: "I get my taco truck love here.",
    },
  },
  "Ray Map Colored.png": {
    1: {
      location: { x: 200, y: 190 },
      text: "There's a man who has been living around this area for forty years.",
    },
    2: {
      location: { x: 270, y: 195 },
      text: "There's a rumor that there's a cave right around here.",
    },
    3: {
      location: { x: 295, y: 250 },
      text: "This is a good place to have privacy.",
    },
    4: {
      location: { x: 360, y: 270 },
      text: "Very good for outdoor sound.",
    },
    5: {
      location: { x: 640, y: 200 },
      text: "Third place I have camped and latest location.",
    },
    6: {
      location: { x: 670, y: 180 },
      text: "Late at night under the bridge here is the best place for drumming.",
    },
    7: {
      location: { x: 620, y: 130 },
      text: "First place I camped at Shellmound",
    },
    8: {
      location: { x: 700, y: 250 },
      text: "Trains and their elegant sounding horns",
    },
    9: {
      location: { x: 290, y: 310 },
      text: "Second place I camped, climbing down rebar ladder",
    },
    10: {
      location: { x: 390, y: 310 },
      text: "Culvert drain I sometimes spend time in.",
    },
  },
};

const useSlider = (min, max, defaultState, label, id) => {
  const [state, setSlide] = useState(defaultState);
  const handleChange = (e) => {
    // console.log("setting level", e.target.value);
    setSlide(e.target.value);
  };

  const props = {
    type: "range",
    id,
    min,
    max,
    step: 0.5,
    value: state,
    onChange: handleChange,
  };
  return props;
};

const MapPin = (props) => {
  const { location, text, image, filename } = props;
  const [textBoxIsShown, setTextBoxIsShown] = useState(false);
  const toggleTextBox = () => {
    // console.log(textBoxIsShown);
    // console.log("image")
    setTextBoxIsShown(!textBoxIsShown);
  };
  console.log("mapp pin activated", text);
  console.log("image: ", image);
  const imagePath = `${process.env.PUBLIC_URL}${imagesForPopupsRootRelativePath}${image}`;
  const pinImageFilename = filenamesToPinIconFilenames[filename];
  const pinImagePath = `${process.env.PUBLIC_URL}${imagesForPinsRootRelativePath}${pinImageFilename}`;
  console.log("image path: ", imagePath);
  console.log(location);
  return (
    <div>
      {textBoxIsShown ? (
        <div className="" style={{ zIndex: 2 }}>
          <p
            className="map-item text-box"
            style={{
              top: (location.y * yRatio).toString() + "vw",
              left: (location.x * xRatio).toString() + "vw",
              backgroundColor: filenamesToColors[filename],
              zIndex: 2,
            }}
          >
            {image ? (
              <img
                className="map-text-box-image"
                src={imagePath}
                // src={`${process.env.PUBLIC_URL}${imagesForPopupsRootRelativePath}${image}`}
                // src={`${process.env.PUBLIC_URL}/ashby-shellmound/public/images/image-overlays/images-for-popups/ashley-popup-1.jpg`}
                // src={"http://placekitten.com/g/200/300"}
                alt="cannot display the thing"
              />
            ) : (
              <div></div>
            )}
            {text}
          </p>
        </div>
      ) : (
        <div className="map-item"></div>
      )}
      <button
        className="map-item"
        onClick={toggleTextBox}
        style={{
          top: (location.y * yRatio).toString() + "vw",
          left: (location.x * xRatio).toString() + "vw",
          zIndex: 1,
        }}
      >
        <img
          src={pinImagePath}
          alt="pin icon"
          className="pin-icon"
          onClick={toggleTextBox}
          style={{
            top: (location.y * yRatio).toString() + "vw",
            left: (location.x * xRatio).toString() + "vw",
            zIndex: 1,
          }}
        />
      </button>
    </div>
  );
};

const MapPins = (props) => {
  const { pins, filename } = props;
  console.log("map pins activated", pins[1]);

  return Object.keys(pins).map((pinID) => {
    const pin = pins[pinID];
    return (
      <MapPin
        location={pin.location}
        text={pin.text}
        image={pin.image}
        filename={filename}
      />
    );
  });
};

const InteractiveMap = () => {
  // const arr = Array(filenames.length);
  // arr.fill(false);
  // var layersStateDict = {};

  const [visibleLayers, setVisibleLayers] = useState({
    "Background inverted google map.png": true,
  });
  const [opacities, setOpacities] = useState({});

  useLayoutEffect(() => {
    // filenames.map((filename) => {
    // layersStateDict[filename] = false;
    // });
    const opacitiesDict = {};

    filenames.forEach((filename) => {
      opacitiesDict[filename] = 90;
    });

    setOpacities(opacitiesDict);
  }, []);

  return (
    <div className="home-page">
      <h1 className="home-page-header">
        Interactive Map of Ashby Shellmound Encampment Area
      </h1>
      <div className="interactive-map-page-text">
        <p>
          {" "}
          To view each artist's map, click on a layer and move the toggle to the
          right. Points of importance are marked by pins. Click any pin to read
          the description and see related images
        </p>
      </div>
      <div className="checkboxes-and-overlay-container">
        <div className="checkboxes">
          {filenames.map((filename) => {
            return (
              <div className="checkbox-container">
                <div className="checkbox-name">{filename.split(" ")[0]}</div>
                <div className="checkbox-innerContainer">
                  <input
                    className="checkbox-checkbox"
                    type="checkbox"
                    checked={visibleLayers[filename]}
                    onChange={() => {
                      const visibleLayersCopy = { ...visibleLayers };
                      visibleLayersCopy[filename] = !visibleLayers[filename];
                      setVisibleLayers(visibleLayersCopy);
                    }}
                  />
                  <input
                    className="checkbox-slider"
                    type="range"
                    name="opacity"
                    minValue="0"
                    maxValue="1"
                    // value="100"
                    value={opacities[filename]}
                    onChange={(event) => {
                      const opacitiesCopy = { ...opacities };
                      opacitiesCopy[filename] = event.target.value;
                      opacitiesCopy["Background inverted google map.png"] =
                        Math.max(
                          opacitiesCopy["Background inverted google map.png"],
                          20
                        );
                      // console.log("value", event.target.value);
                      setOpacities(opacitiesCopy);
                    }}
                    step="any"
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="overlay-container">
          {filenames.map((filename) => {
            // console.log(opacities[filename]);
            // console.log(filename);
            // console.log(filename in pinsData);
            return visibleLayers[filename] ? (
              <div className="img-and-pins-container">
                <img
                  className="overlay-image"
                  src={`${process.env.PUBLIC_URL}${overlayImagesRootRelativePath}${filename}`}
                  style={{ opacity: opacities[filename] / 100 }} //why isn't this 0 to 1? This seems wrong but it works...
                  alt="error"
                />
                {filename in pinsData ? (
                  <MapPins pins={pinsData[filename]} filename={filename} />
                ) : (
                  <div></div>
                )}
              </div>
            ) : (
              <div></div>
            );
          })}

          {/* <button className='map-pin'>my div</button> */}
        </div>
      </div>
      <div className="interactive-map-page-text">
        <p>
          Each artist created an individual drawn or painted map of their
          knowledge, history, and patterns of use for the geographic area of the
          Ashby/Shellmound encampment. These maps are digitized, layered
          together, and collectively form both an artwork and an interactive
          document to highlight and preserve a community's intangible heritage
          which is lost at the time of eviction.
        </p>{" "}
      </div>
      <div className="below-overlay-images-title">
        Additional Maps of Ashby Shellmound Created By Participating Artists
      </div>
      <div className="below-overlay-images">
        {belowOverlayImageFilenames.map((filename) => {
          const imagePath = `${process.env.PUBLIC_URL}${belowOverlayImagesRootRelativePath}${filename}`;
          console.log("image path: ", imagePath);
          return (
            <div className="below-overlay-image-box">
              <img
                className="below-overlay-image"
                src={imagePath}
                alt="could not display"
              />
              <figcaption className="below-overlay-image-text">
                {belowOverlayCaptions[filename]}
              </figcaption>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InteractiveMap;

// {/* <img
//   src={`${process.env.PUBLIC_URL}${overlayImagesRootRelativePath}${filename1}`}
//   alt={`${process.env.PUBLIC_URL}${overlayImagesRootRelativePath}${filename2}`}
//   className='modal-image'
// /> */}
// {/* <img src={require("../public/images/image-overlays/Interactive-Drawn-Map-Files/Ashley-Painted-Map-Full-Opacity.png")} alt={"error"} /> */}
// {/* <img alt="error" src="../public/images/image-overlays/Interactive-Drawn-Map-Files/Ashley-Painted-Map-Full-Opacity.png" /> */}
// {/* <div className='overlay-container'>
//   <div>Hello world</div>
//   <img className='overlay-image' src={`${process.env.PUBLIC_URL}${overlayImagesRootRelativePath}${filename1}`} alt="error" />
//   {/* <img classname='overlay-image' src={`${process.env.PUBLIC_URL}${overlayImagesRootRelativePath}${filename2}`} alt="error" /> */}

// {/* <Overlay className='overlay'
//     url={`${process.env.PUBLIC_URL}${overlayImagesRootRelativePath}${filename1}`} // required
//     overlayUrl={`${process.env.PUBLIC_URL}${overlayImagesRootRelativePath}${filename2}`} // required
//     imageHeight={100}
//     position={'center'}
//     overlayWidth={100}
//     overlayHeight={100}
//     overlayPadding={0}
//     watermark={false}
//   /> */}
// </div> */}
