import "./HomePage.css";

const HomePage = () => {
  return (
    <div className='home-page'>
      <div className='home-page-text'>
        <h3 className='home-page-header'>Project Description</h3>
        <p className='home-page-paragraph'>
          The People are the Place is a multi media community art project
          undertaken by eight artists living in the Berkeley/Emeryville area,
          and in collaboration with Street Spirit Newspaper. Six of the
          participating artists are current or former residents of the
          Ashby/Shellmound encampment located at the I-80 interchange. This
          encampment area, occupied by unhoused individuals on and off for at
          least the last 20 years, is currently undergoing a process of
          evictions by Cal Trans and will be completely cleared by March 2022.
          In light of these evictions, this project seeks to explore the
          following questions:
        </p>
        <p className='home-page-paragraph'>
          <ul>
            <li className='home-page-list'>
              By what means do we remember or understand who we are (as artists,
              individuals, and a community) if we become displaced?{" "}
            </li >
            <li className='home-page-list'>
              What role does the environment of the encampment and surrounding
              neighborhood play in creating a sense of place and community for
              encampment residents?
            </li>
            <li className='home-page-list'>
              How can the intra-community relationships between individuals,
              creative practices, and the environment be mapped?
            </li>
            <li className='home-page-list'>
              How can this information be effectively conveyed and shared with
              the larger community?
            </li>
          </ul>
        </p>
        <p className='home-page-paragraph'>
          The project began with a group of artists wanting to find a way to
          share their artwork with the larger community. This artwork had nearly
          all been created while living in the Ashby/Shellmound encampment
          community, often with artists inspiring each other. As project
          collaborator D'artagnan Lloyd put it, “It's kind of a group
          consciousness. You can see the influence of your own art reflected
          back to you”.
        </p>
        <p className='home-page-paragraph'>
          While the project's development and results will be documented on an
          extensive website which is currently under construction, the process
          can be broken down into three phases:
        </p>
        <h3 className='home-page-header'>Phase one (August – December 2021):</h3>
        <p className='home-page-paragraph'>
          Using video and photography, art created by the six artists from
          Ashby/Shellmound is extensively documented. This is especially
          important as many artists lose their work in the process of
          displacement from their encampments. Seeking a way for the artists to
          continue to connect with each other creatively as the neighborhood is
          cleared and the community dispersed, the project has undertaken to
          create an interconnected map of artworks. This consists of each
          participating artist selecting works from their own portfolios and
          pairing this work with the work of another person in the project.
          Through this process, many different artworks were connected, and a
          web of connections formed. While the reasons for pairing each work
          were personal and incredibly diverse, the process of creating this art
          “map” provided a way for the artists to stay connected with each other
          through inspiration and to reflect upon the value of the relationships
          and the environment at Ashby/Shellmound and its impact upon their own
          creative processes. This map will be interactive and will be displayed
          on the project website, coming soon.
        </p>
        <h3>Phase Two: (October – December 2021)</h3>
        <p className='home-page-paragraph'>
          Walking the land and drawing maps. In collaboration with Street Spirit
          newspaper, each artist spent time walking the land of the
          Ashby/Shellmound camp, both before and after eviction, and reflecting
          on the land and the people who have passed through it. In connection
          with these discussions, each artist created an individual drawn map of
          their knowledge, history, and patterns of use for the geographic area
          of the Ashby Shellmound encampment. These maps are digitized, layered
          together, and collectively form both an artwork and an interactive
          document to highlight and preserve a community's intangible heritage
          which is lost at the time of eviction. This map will be interactive
          and available on the project website.
        </p>
        <p className='home-page-paragraph'>
          <b>Note:</b> The March 2022 edition of Street Spirit newspaper will be
          entirely devoted to sharing these maps and accompanying texts. This
          date is chosen to coincide with the final deadline for eviction of all
          individuals living at Ashby/Shellmound.
        </p>
        <h3 className='home-page-header'>Phase Three: (August 2021 – February 12 2022)</h3>
        <p className='home-page-paragraph'>
          Artists work together to curate and create a 360 degree video
          projection installation, projecting the artwork of Ashby/Shellmound
          artists on to the circle of trees surrounding the fountain within
          Civic Center Park. The projections will be accompanied by three
          kinetic sculptures created by participating artists which incorporate
          audio recordings of the Ashby/Shellmound environment and its
          residents. This multi-media installation will immerse the viewer into
          the visions and experiences of individuals currently or formerly
          living at the camp. The chosen location centers the creative
          expressions of this marginalized community within the civic heart of
          Berkeley.`
        </p>
      </div>
    </div>
  );
};

export default HomePage;
